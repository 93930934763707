@import "components/topheading";


.contactpage {
  .container {
    max-width: 930px;
  }

  .form-info {
    background: $fadeblue;
  }


  .contactform {
    padding-bottom: 50px;
    p {
      $min_width: 320px;
      $max_width: 900px;
      $min_font: 13px;
      $max_font: 15px;
      $min_line: 20px;
      $max_line: 24px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
      font-family: 'Nunito Sans';
    }


    .form-content {
      display: flex;
      flex: 1;
      flex-direction: row;
      // border: 2px solid #054379;


      .button {
        min-width: 200px;
        padding: 17px 20px;
        border: 0;
      }

      .logininput2 {
        display: block;
        position: relative;
        font-family: 'Nunito Sans', sans-serif;
        margin-bottom: 80px;
        cursor: text;
      }

      .input__field {
        display: block;
        width: 100%;
        $min_width: 320px;
        $max_width: 425px;
        $min_font: 15px;
        $max_font: 18px;
        $min_line: 20px;
        $max_line: 24px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        border: 0;
        border-bottom: 1px solid #CCC;
        padding-bottom: 7px;
        transition: .25s;
      }
      .input__field:focus, .input__field:active {
        outline: 0;
        border-color:$primary;
      }

      .input__field:placeholder-shown + .input__name {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        color: #7b7b7b;
        $min_width: 320px;
        $max_width: 425px;
        $min_font: 14px;
        $max_font: 18px;
        $min_line: 20px;
        $max_line: 24px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-weight: 400;
        position:absolute;
        pointer-events:none;
        top:3px;
        margin-bottom: 10px;
        transition:0.2s ease all;
        -moz-transition:0.2s ease all;
        -webkit-transition:0.2s ease all;
      }

      .input__name, .input__field:focus ~ .input__name {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        top:-22px;
        padding:0px;
        font-size:14px;
        padding-left: -5px;
        color:$primary;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: $primary;
        position: absolute;
        transition: .25s;
      }



      /* BOTTOM BARS ================================= */
      .logininput2 .bar 	{ position:relative; display:block; width:100%; }
      .logininput2 .bar:before, .logininput2 .bar:after 	{
        content:'';
        height:1.5px;
        width:0;
        bottom:0.5px;
        position:absolute;
        background:$primary;
        transition:0.2s ease all;
        -moz-transition:0.2s ease all;
        -webkit-transition:0.2s ease all;
      }
      .logininput2 .bar:before {
        left:50%;
      }
      .logininput2 .bar:after {
        right:50%;
      }

      /* active state */
      .logininput2 input:focus ~ .bar:before, .logininput2 input:focus ~ .bar:after {
        width:50%;
      }

      /* HIGHLIGHTER ================================== */


      /* active state */
      input:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
      }

      /* ANIMATIONS ================ */
      @-webkit-keyframes inputHighlighter {
        from { background:$primary; }
        to 	{ width:0; background:transparent; }
      }
      @-moz-keyframes inputHighlighter {
        from { background:$primary; }
        to 	{ width:0; background:transparent; }
      }
      @keyframes inputHighlighter {
        from { background:$primary; }
        to 	{ width:0; background:transparent; }
      }

      .contact-info form {
        margin-top: 25px;
      }

      .contact-info p {
        font-size: calc(18px + .15vw);
        margin-top: 20px;
      }

      .companyNameLabel {
        display: none;
      }
    }


    .card-contact-info {
      h3 {
        $min_width: 320px;
        $max_width: 900px;
        $min_font: 25px;
        $max_font: 30px;
        $min_line: 30px;
        $max_line: 40px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-family: 'Metropolis';
        font-weight: 500;
        letter-spacing: 1.3;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $primary;
      }

      p {
        $min_width: 320px;
        $max_width: 900px;
        $min_font: 18px;
        $max_font: 20px;
        $min_line: 20px;
        $max_line: 30px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-family: 'Nunito Sans';
        letter-spacing: 1.3;
        font-weight: 300;
        margin-bottom: 15px;
        color: $primary;

        span {
          font-weight: 400;
        }

        a {
          color: $primary;
        }

      }
    }


    .card-large {
      max-height: 100%;
      max-width: 410px;

      .card-image {
        background: url(../../staticImages/rudi.png);
        height: 300px;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: bottom center;
        background-size: contain;
      }


      .card-contact-info {
        h3 {
          $min_width: 320px;
          $max_width: 900px;
          $min_font: 19px;
          $max_font: 28px;
          $min_line: 38px;
          $max_line: 40px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
          font-weight: 200;
          letter-spacing: 1.5;
          margin-top: 12px;
          margin-bottom: 12px;
          color: $primary;
        }

        h4 {
          $min_width: 320px;
          $max_width: 900px;
          $min_font: 19px;
          $max_font: 26px;
          $min_line: 35px;
          $max_line: 40px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
          font-family: 'Nunito Sans';
          font-weight: 400;
          letter-spacing: 1.5;
          margin-top: 12px;
          margin-bottom: 12px;
          color: $primary;
        }

        p {
          $min_width: 320px;
          $max_width: 900px;
          $min_font: 16px;
          $max_font: 20px;
          $min_line: 26px;
          $max_line: 30px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
          font-weight: 300;
          margin-bottom: 10px;
          color: $primary;
        }
      }
    }

    .card-contact-lv {
      min-height: 280px;
    }

    .card-map {
      min-height: 230px;
      border-radius: 13px 13px 0 0;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      background-color: #eaeaea;
    }

    .map-be {
      @extend .card-map;
      background-image: url(../../staticImages/map-be.png);
    }
    .map-lv {
      @extend .card-map;
      background-image: url(../../staticImages/map-lv.png);
    }

    .card-directions {
      h3 {
        $min_width: 320px;
        $max_width: 900px;
        $min_font: 18px;
        $max_font: 26px;
        $min_line: 38px;
        $max_line: 40px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        margin-bottom: 15px;
      }
      p {
        $min_width: 320px;
        $max_width: 900px;
        $min_font: 16px;
        $max_font: 20px;
        $min_line: 30px;
        $max_line: 30px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        color: #242424;
        font-weight: 300;
        margin-bottom: 14px;

        a {
          margin-top: 14px;
          font-weight: 300;
          color: lighten(#164EC7, 15%);
          text-decoration: none;

          .arrow-icon {
            color: lighten(#164EC7, 25%);

            i {
              transform: scale(.8) translateX(5px) translateY(2px);
              transition: transform .2s;
            }
          }

          &:hover {
            color: darken(#164EC7, 5%);
            .arrow-icon {
              //color: $secondary;

              i {
                color: #164EC7;
                transform: scale(1.1) translateX(12px) translateY(1px);
              }
            }
          }

        }
      }
    }

  }

  .uw-info-card {
    padding: 0px;

    h3 {
      $min_width: 320px;
      $max_width: 1500px;
      $min_font: 23px;
      $max_font: 25px;
      $min_line: 30px;
      $max_line: 36px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
    }
  }

  @media(max-width:410px) {
    .button {
      min-width: 150px;
      padding: 15px 20px;
      margin: 0.5em auto;
    }
    textarea {
      height: 70px;
    }
  }


  @media(min-width:768px) {
    .card-contact-lv {
      min-height: 526px !important;
    }

    .card-large {
      max-width: 874px !important;
      max-height: 300px;
      margin-top: 15px;
    }

    .card-contact {
      padding: 40px;
    }
  }
}