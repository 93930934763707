// Styling for the portfolio section
#customers {
  background: $white;
  padding-bottom: 100px;
  padding-top: 80px;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(1fr, auto);
    grid-gap: 10px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .customer-item {
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.08);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.08);
    box-shadow: 0 5px 10px rgba(0,0,0,.08);
    background: white;
    border-radius: 6px;
    padding: 10px 22px;


    .customer-caption {
      margin: 0 auto;
      //padding: 25px;
      text-align: center;
      h4 {
        margin: 0;
        text-transform: none;
      }
      p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
      }
      img {
        height: 65px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
  * {
    z-index: 2;
  }

  @media(min-width:450px) {
    .container {
      grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    }
  }
}


@media(min-width:450px) {
  #customers {
    margin-right: 30px;
    margin-left: 30px;
  }
}

@media(max-width:1280px) {
  #customers {
    padding-top: 30px;

    .container {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}