$min_width: 320px;
$max_width: 1000px;
$min_font: 15px;
$max_font: 21px;
$min_line: 26px;
$max_line: 35px;

#simple-text {
  @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
  max-width: 1000px;
  min-height: 75vh;
  margin-top: 130px;
  margin-bottom: 130px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'Nunito Sans', sans-serif;

  h1, h2, h3, h4, h5 {
    color: $primary;
  }

  h1 {
    font-size: 38px;
    line-height: 70px;
  }

  h2 {
    font-size: 21px;
    line-height: 35px;
  }

  @media(min-width:450px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media(min-width:768px) {
    padding-right: 100px;
    padding-left: 100px;
    h1 {
      font-size: 42px;
      line-height: 65px;
    }

    h2 {
      font-size: 22px;
      line-height: 40px;
    }
  }


  @media(min-width:1024px) {
    padding-right: 150px;
    padding-left: 150px;
    h1 {
      font-weight: 200;
      font-size: 50px;
      line-height: 70px;
    }


    h2 {
      font-size: 25px;
      line-height: 40px;
    }
  }

  @media(min-width:1280px) {
    padding-right: 50px;
    padding-left: 50px;
  }


  @media(min-width:1440px) {
    h1 {
      font-size: 55px;
      line-height: 80px;
    }

    h2 {
      font-size: 30px;
      line-height: 45px;
    }
  }


  @media(min-width:1680px) {
    h1 {
      font-size: 65px;
      line-height: 100px;
    }

    h2 {
      font-size: 35px;
      line-height: 55px;
    }
  }


  @media(min-width:2100px) {
    h1 {
      font-size: 75px;
      line-height: 110px;
    }

    h2 {
      font-size: 40px;
      line-height: 60px;
    }
  }
}
