
.underline--magical {
  background-image: linear-gradient(120deg, #5687ff 0%, $secondary 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88%;
  }
}