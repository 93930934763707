// Styling for the footer
footer {
  padding: 25px 0;
  text-align: center;
  background-color: $footer;
  margin-top: 0;

  .copyright-footer {
    min-height: 60px;
    background-color: $footer;
    display: grid;
    margin-bottom: -30px;
    color: $white;

    a {
      color: $white;
    }

    span.copyright {
      font-size: 90%;
      line-height: 40px;
      text-transform: none;
      //@include heading-font;
    }
    ul.quicklinks {
      font-size: 90%;
      line-height: 40px;
      text-transform: none;
      //@include heading-font;
    }
  }


  #main-footer {
    $min_width: 320px;
    $max_width: 1280px;
    $min_font: 15px;
    $max_font: 17px;
    $min_line: 25px;
    $max_line: 30px;

    @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
    .footer-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    h5 {
      font-weight: 500;
      font-family: 'Metropolis', sans-serif;
      @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);

      a {
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
      }
    }

    .footer-info {
      display: grid;
      //align-items: center;
      justify-content: start;

        h5 {
          @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
          color: $white;
          text-align: left;
        }
      }

    .footer-member {
      display: grid;
      align-items: start;
      justify-content: center;

      h5 {
        color: $white;
      }

    }

    .menu-copy{
      color: $white;
      float: right;
      text-align: right;

      h5 {
        margin: 0;
        a {
          color: #fff;
          text-decoration: none;

          &:hover {
            color: $secondary;
          }

        }
      }

      a:visited {
        color: inherit;
        color: $white;
      }
      .button--slim {
        font-size: 0.95em;
        padding: 18px 30px;
        min-width: 0;
      }
      a {
        color: $white;
        transition: color 0.2s;
      }

    }
  }


  @media(max-width:768px) {

    #main-footer {
      .footer-menu {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .footer-info {
        display: grid;
        align-items: center;
        justify-content: center;

        h5 {
          color: $white;
          text-align: center;
        }
      }

      .menu-copy {
        color: $white;
        text-align: center;
      }
    }
  }


}


