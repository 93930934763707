.intro {
  padding-top: 50px;
  padding-bottom: 70px;
  margin-top: 25px;
  text-align: left;
  .intro-text {
    padding-top: 65px;
    padding-bottom: 0;
    .intro-heading {
      padding: 20px;
      text-align: center;
      h1 {
        $min_width: 320px;
        $max_width: 1280px;
        $min_font: 24px;
        $max_font: 48px;
        $min_line: 32px;
        $max_line: 75px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        color: darken($primary, 4%);
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
    .intro-subheading {
      text-align: center;
      h2 {
        $min_width: 320px;
        $max_width: 1280px;
        $min_font: 21px;
        $max_font: 40px;
        $min_line: 35px;
        $max_line: 60px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 300;
        color: $primary;
      }
    }
  }
}



