// Styling for the services section

.aboutpage {
  #services {
    .container {
      padding-top: 0px;
    }
  }
}


#services {
  background: $fadeblue;
  padding-bottom: 0px;

  .bullet {
    list-style-type: none;
  }

}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

.service-card {
  display: grid;
  color: white;
  background: $primary;
  min-height: 230px;
  padding: 25px 40px 10px;
  //border-radius: 26px;
  border: solid 6px $fadeblue;

  h3 {
    $min_width: 320px;
    $max_width: 1280px;
    $min_font: 24px;
    $max_font: 30px;
    $min_line: 32px;
    $max_line: 60px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
    display: flex;
    align-items: start;
    justify-content: start;
  }

  ul {
    display: grid;
    color: $white;
    align-items: end;
    text-align: left;
    padding-top: 5px;
    padding-left: 0;

    li {
      padding-bottom: 15px;

      a {
        color: #fff;
        text-decoration: none;
        outline: none;

        .arrow-icon {
          color: $white;

          i {
            transform: scale(.8) translateX(5px) translateY(2px);
            transition: transform .1s;
          }
        }

        &:hover {
          text-decoration: none;

          .arrow-icon {
            //color: $secondary;

            i {
              transform: scale(1.2) translateX(12px) translateY(1px);
            }
          }
        }
      }
    }
  }
}

.service-contact {
  background: $fadeblue;
  color: $text;
  .contact-div {
    align-self: center;
    h3 {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      color: $primary;
      $min_width: 320px;
      $max_width: 1280px;
      $min_font: 23px;
      $max_font: 28px;
      $min_line: 32px;
      $max_line: 60px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
    }
    p {
      color: $text;
    }
  }
}



@media (min-width:768px){
  .aboutpage {
    #services {
      .container {
        padding-top: 80px;
      }
    }
  }

  #services {
    padding-bottom: 100px;
    .service-contact {
      background: #fff;
    }
  }
}