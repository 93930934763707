// Core variables and mixins
@import "components/variables";
@import "components/mixins";
//@import "colors";

// Global CSS
@import "components/global";

// Components
@import "components/navbar";
@import "components/buttons";
@import "components/cards";
@import "components/animations";
@import "components/masthead";
@import "components/underline";
@import "components/aboutpage";
@import "components/services";
@import "components/customers";
@import "components/cases";
@import "components/contact";
@import "components/contactpage";
@import "components/simpletext";
@import "components/footer";
@import "components/specialheading";
@import "components/test";
