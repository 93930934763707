// ================= LOADING ==============================


// HTML

//<div class="container">
//<div class="coast">
//<div class="wave-rel-wrap">
//<div class="wave"></div>
//</div>
//</div>
//<div class="coast delay">
//<div class="wave-rel-wrap">
//<div class="wave delay"></div>
//</div>
//</div>
//<div class="text text-u">U</div>
//<div class="text text-n">n</div>
//<div class="text text-i">i</div>
//<div class="text text-w">W</div>
//<div class="text text-e">e</div>
//<div class="text text-b">b</div>
//</div>




#loading {
  $textDur: 1350ms;
  $waveDur: 2700ms;

  html, body {
    font-size: 62.5%;
    background-color: #00091B;
    font-family: 'Muli', Helvetica, sans-serif;
  }

  .container {
    position: absolute;
    width: 25rem;
    height: 10rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .text {
    color: #fff;
    font-size: 6rem;
    font-weight: 900;
    display: inline-block;
    position: absolute;
    bottom: 0;
    animation: text $textDur ease-in infinite;
    transform: translatez(0);
    &-u {
      left: 0rem;
      transform: translate3d(0, -4rem, 0);
      animation: text-w $textDur ease-in infinite;
    }
    &-n {
      left: 5.4rem;
      animation-delay: 150ms;
    }
    &-i {
      left: 9.6rem;
      animation-delay: 235ms;
    }
    &-w {
      left: 11.5rem;
      animation-delay: 315ms;
    }
    &-e {
      left: 18.3rem;
      animation-delay: 390ms;
    }
    &-b {
      left: 22rem;
      animation-delay: 470ms;
    }
  }

  .coast { // wave: translate-x
    position: absolute;
    left: 1.3rem;
    bottom: 1.7rem;
    width: 6.2rem;
    height: 2.2rem;
    display: inline-block;
    animation: coast $waveDur linear infinite;
    transform: translateZ(0);
  }

  .wave-rel-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 3rem;
    perspective-origin: 0%, 50%;
  }

  .wave { // wave: width && scale && rotate
    position: absolute;
    width: 6.2rem;
    height: 2.2rem;
    border-radius: 1.1rem;
    background: radial-gradient(ellipse at center, rgba(116, 241, 252, 1) 0%, rgba(50, 230, 255, 1) 73%, rgba(21, 199, 250, 1) 100%);
    display: inline-block;
    animation: wave $waveDur linear infinite;
    will-change: width;
    transform: translateZ(0) scale(0);
  }

  .delay {
    animation-delay: $waveDur / 2;
  }

  @keyframes text {
    0% {
      transform: translate3d(0, 0, 0);
    }
    30% {
      transform: translate3d(0, 0, 0);
    }
    45% {
      transform: translate3d(0, -4rem, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes text-w {
    0% {
      transform: translate3d(0, -4rem, 0);
    }
    38% {
      transform: translate3d(0, -4rem, 0);
    }
    69% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -4rem, 0);
    }
  }

  @keyframes coast {
    0% {
      transform: translate3d(0, 0, 0);
    }
    23% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(21rem, 0, 0);
    }
    100% {
      transform: translate3d(21rem, 0, 0);
    }
  }

  @keyframes wave {
    0% {
      transform: scale(0);
      width: 2.2rem;
    }
    10% {
      transform: scale(1);
    }
    23% {
      width: 2.2rem;
    }
    32% {
      width: 6.1rem;
    }
    42% {
      width: 5rem;
      transform: rotateY(0deg) scale(1, 1);
    }
    51% {
      transform: rotateY(90deg) scale(0.6, 0.2);
    }
    52% {
      transform: rotateY(90deg) scale(0);
    }
    100% {
      transform: rotateY(90deg) scale(0);
    }
  }
}






//==============================

  .wave-container {
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    .wave {
      width: 250px;
      height: 250px;
      opacity: 0.6;
      background-color: #00c6f01c;
      border-radius: 5%;
      position: absolute;
      top: 50%;
      right: -15%;
      outline: 1px solid transparent;
      -webkit-backface-visibility: hidden;
      will-change: transform;
      -webkit-perspective: 1000;
      -webkit-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;
      //-webkit-animation: spin 30s ease-in-out infinite;
      //animation: spin 30s ease-in-out infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 30s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -webkit-animation-fill-mode: forwards;

      &:nth-of-type(2) {
        top: 55%;
        right: -10%;
      }
      &:nth-of-type(3) {
        top: 60%;
        right: -5%;
      }
    }
    //&:hover {
    //  .wave {
    //    background-color: #00c4f4;
    //    width: 600px;
    //    height: 600px;
    //    top: 320px;
    //    border-radius: 40%;
    //    -webkit-animation-duration: 12s;
    //    animation-duration: 12s;
    //    -webkit-transition: all 0.7s;
    //    -o-transition: all 0.7s;
    //    transition: all 0.7s;
    //    &:nth-of-type(2) {
    //      -webkit-animation-duration: 15s;
    //      animation-duration: 15s;
    //      top: 330px;
    //    }
    //    &:nth-of-type(3) {
    //      top: 338px;
    //      -webkit-animation-duration: 20s;
    //      animation-duration: 20s;
    //    }
    //  }
    //}
    .content {
      z-index: 1001;
    }
  }

@keyframes spin {
   0% {
     -webkit-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }

@keyframes spin2 {
  0% {
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin3 {
  0% {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin3 {
  0% {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}