/* NAVBAR
________________________________________*/
.nav {
  display: block;
  list-style: none;
  font-size: 0.9em;
  top: 0;
  transition: all 0.2s;
  width: 100%;
  background: $white;
  z-index: 10001;
  letter-spacing: 0.05em;

  .fluid-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
    display: flex;
    align-items: center;

    img {
      height: 32px;
    }

    svg {
      height: 25px;
    }

  }

  svg {

    //.cls-1 {
    //  transition: 0.3s;
    //  fill: $text-main;
    //}
    //
    //.cls-2 {
    //  transition: 0.3s;
    //  fill: $text-main;
    //}

    &:hover{
      .cls-1 {
        fill: $primary;
        transition: 0.4s;
      }

      .cls-2 {
        fill: $primary;
        transition: 0.4s;
      }
    }
  }

  a {
    letter-spacing: 0.04em;
    text-decoration: none;
  }

  a.active {
    font-weight: 500;
    color: #2b5cac;
  }

   a:hover:after {
    left: 40px;
    right: 42px;
    text-decoration: none;
     font-weight: 500;
  }
}


.fixed {
  position: fixed;
  padding: 10px 0;
  z-index: 10001;
}

.scrolled {
  background: $white;
  box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  -webkit-box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  -moz-box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  z-index: 10001;
  @include fixed(top 0 left 0);
}



.logo--sm {
  font-size: 1em;
  line-height: 2.5em;
}
.logo {
  color: #000;
  display: block;
  font-size: 1.4em;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}


.nav__link {
  font-size: 1em;
  padding: 1em;
  //text-transform: uppercase;
}

.nav .button {
  margin: 0 0 0 1.5em;
}




.nav-mobile {
    display: block;
    list-style: none;
    padding: 15px 0px;
    top: 0;
    transition: all 0.2s;
    width: 100%;
    z-index: 19999;
    overflow-x: hidden;

    $button-height: 24px;
    $button-width: 28px;


    .text-left {
      text-align: left;
      display: flex;
      align-items: center;

      img {
        height: 23px;
      }

      svg {
        height: 23px;
      }
    }

    svg {

      //.cls-1 {
      //  transition: 0.3s;
      //  fill: $text-main;
      //}
      //
      //.cls-2 {
      //  transition: 0.3s;
      //  fill: $text-main;
      //}

      &:hover{
        .cls-1 {
          fill: $primary;
          transition: 0.4s;
        }

        .cls-2 {
          fill: $primary;
          transition: 0.4s;
        }
      }
    }



    h1 {
      position: relative;
      text-align: center;
    }

    .button_container {
      position: absolute;
      top: 16px;
      right: 15px;
      height: $button-height;
      width: $button-width;
      cursor: pointer;
      z-index: 20001;
      transition: opacity .25s ease;


      &:hover {
        opacity: .7;
      }

      &.active {
        .top {
          transform: translateY(9px) translateX(0) rotate(45deg);
          background: $white;
        }
        .middle {
          opacity: 0;
          background: $white;
        }

        .bottom {
          transform: translateY(-9px) translateX(0) rotate(-45deg);
          background: $white;
        }
      }

      span {
        background: $primary;
        border: none;
        height: 3px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        transition:  all .35s ease;
        cursor: pointer;

        &:nth-of-type(2) {
          top: 9px;
        }

        &:nth-of-type(3) {
          top: 18px;
        }
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      opacity: 1;
      visibility: hidden;
      -webkit-transition: opacity .35s, visibility .35s, width .35s;
      -o-transition: opacity .35s, visibility .35s, width .35s;
      transition: opacity .35s, visibility .35s, width .35s;
      z-index: 20000;

      &:before {
        content: '';
        background: $primary;
        left: -55%;
        top: 0;
        width: 50%;
        height: 100%;
        position: absolute;
        -webkit-transition: left .35s ease;
        -o-transition: left .35s ease;
        transition: left .35s ease;
      }

      &:after {
        content: '';
        background: $primary;
        right: -55%;
        top: 0;
        width: 50%;
        height: 100%;
        position: absolute;
        -webkit-transition: all .35s ease;
        -o-transition: all .35s ease;
        transition: all .35s ease;
      }

      &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }

        li {
          -webkit-animation: fadeInRight .5s ease forwards;
          animation: fadeInRight .5s ease forwards;
          -webkit-animation-delay: .35s;
          animation-delay: .35s;
          &:nth-of-type(2) {
            -webkit-animation-delay: .45s;
            animation-delay: .45s;
          }
          &:nth-of-type(3) {
            -webkit-animation-delay: .55s;
            animation-delay: .55s;
          }
          &:nth-of-type(4) {
            -webkit-animation-delay: .65s;
            animation-delay: .65s;
          }
        }
      }
      nav {
        position: relative;
        height: 70%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        z-index: 10001;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        height: 100%;

        li {
          display: block;
          height: 20%;
          height: calc(100% / 5);
          min-height: 40px;
          position: relative;
          opacity: 0;

          a {
            display: block;
            position: relative;
            color: $white;
            text-decoration: none;
            overflow: hidden;

            &:hover:after,
            &:focus:after,
            &:active:after {
              width: 35%;
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0%;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
              height: 3px;
              background: $white;
              -webkit-transition: .35s;
              -o-transition: .35s;
              transition: .35s;
            }
          }
        }
      }
    }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }


  .button, .button:visited, .loading .button:hover {
    background: $white;
    color: $primary;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    line-height: 1;
    margin: 1em auto;
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s;
    min-width: 230px;
    border: 2px solid #fff;
  }
  a:visited {
    color: inherit;
  }
  .button--slim {
    font-size: 0.95em;
    padding: 18px 30px;
    min-width: 0;
  }
  a {
    color: $primary;
    transition: color 0.2s;
  }

  .button:hover {
    background: $primary;
    color: $white;
    border: 2px solid $primary;
    text-decoration: none;
  }


}


.fixed2 {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  padding: 15px 0px;
  z-index: 10001;
  margin-bottom: -85px;
  background: $white;
  box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  -webkit-box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  -moz-box-shadow: 0 0 0 4px rgba(182,204,231,0.3);
  position: sticky;
  position: -webkit-sticky; /* Safari */
  overflow: visible;
}




@media (min-width: 1024px) {
  .nav {
    display: block;
  }

  .nav-mobile{



    display: none;
  }
}

@media (max-width: 1024px) {
  .nav {
    display:none;
  }

  .nav-mobile{
    display: block;
  }
}




//
//.aboutpage, .contactpage {
//  .nav {
//    background: $primary;
//
//    a {
//      color: $white;
//    }
//    a:hover {
//      color: $secondary;
//    }
//
//    svg {
//
//      .cls-1 {
//        transition: 0.3s;
//        fill: $white;
//      }
//
//      &:hover {
//        .cls-1 {
//          fill: $secondary;
//          transition: 0.4s;
//        }
//
//        .cls-2 {
//          fill: $secondary;
//          transition: 0.4s;
//        }
//      }
//    }
//
//    .fixed {
//      box-shadow: 0 0 0 4px rgba(66,48,58,0.08);
//      -webkit-box-shadow: 0 0 0 4px rgba(66,48,58,0.08);
//      -moz-box-shadow: 0 0 0 4px rgba(66,48,58,0.08);
//      padding: 20px 60px !important;
//      z-index: 10001;
//      @include fixed(top 0 left 0);
//    }
//
//  }
//
//  .nav-mobile {
//    background: $primary;
//    svg {
//
//      .cls-1 {
//        transition: 0.3s;
//        fill: $white;
//      }
//
//      &:hover {
//        .cls-1 {
//          fill: $secondary;
//          transition: 0.4s;
//        }
//
//        .cls-2 {
//          fill: $secondary;
//          transition: 0.4s;
//        }
//      }
//    }
//  }
//}


