.button, .button:visited, .loading .button:hover {
    background: $primary;
    color: $white;
    display: inline-block;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    margin: 1em auto;
    padding: 20px 30px;
    text-align: center;
    transition: all 0.3s;
    text-transform: uppercase;
    min-width: 200px;
    -webkit-box-shadow: 0px 2px 4px 2px rgba(198, 205, 214, 0.4);
    -moz-box-shadow: 0px 2px 4px 2px rgba(198, 205, 214, 0.4);
    box-shadow: 0px 2px 4px 2px rgba(198, 205, 214, 0.4);
    border-radius: 6px;
  }

.button--slim {
  font-size: 0.95em;
  padding: 15px 20px;
  min-width: 0;
  text-transform: none;
}
a {
  color: rgba(23, 23, 23, 0.79);
  transition: color 0.3s;
}

.button:hover {
  background: $white;
  color: lighten($primary, 10%);
  text-decoration: none;
  -webkit-box-shadow: inset 0px 0px 0px 1px #9bbbe3;
  -moz-box-shadow: inset 0px 0px 0px 1px #9bbbe3;
  box-shadow: inset 0px 0px 0px 1px #9bbbe3;
}



.button-secondary, .button-secondary:visited, .button-secondary:hover {
    background: $white;
    -webkit-box-shadow: inset 0px 0px 0px 1px #e0e0e0;
    -moz-box-shadow: inset 0px 0px 0px 1px #e0e0e0;
    box-shadow: inset 0px 0px 0px 1px #e0e0e0;
    color: #4473da !important;
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    font-size: 0.95em;
    padding: 15px 20px;
    text-align: center;
    transition: all 0.3s;
    min-width: 0;
    border-radius: 8px;
  }

.button-secondary:hover {
    background: $fadeblue;
    color: #295dce;
    -webkit-box-shadow: inset 0px 0px 0px 1px #9bbbe3;
    -moz-box-shadow: inset 0px 0px 0px 1px #9bbbe3;
    box-shadow: inset 0px 0px 0px 1px #9bbbe3;
    text-decoration: none;

  }