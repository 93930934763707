
.uniweb-card {
  position: relative;
  background: #fff;
  border-radius: 13px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
  -moz-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
  box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
  transition: .3s ease;
  min-height: 280px;
}

.card-small {
  max-width: 410px;
}

.card-large {
  max-width: 874px;
}

.uw-card-blue {
  background: $fadeblue;
}

.uw-card-on-white {
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
  //-webkit-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.30);
  //-moz-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.30);
  //box-shadow: 0 4px 15px rgba(200, 213, 243, 0.30);
  //border: 2px solid #fafcff;
}
