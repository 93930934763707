@import "components/topheading";

.aboutpage {

  .mission_section {
    margin-top: 0;
  }

  .goal_mission {
    max-width: 1000px;
    h2 {
      padding-top: 0px !important;
    }
  }

  //#services {
  //  padding-bottom: 120px;
  //  padding-top: 0;
  //}
  //
  //#cases {
  //  padding-bottom: 3vw !important;
  //}

  .container {
    max-width: 1280px;
  }

  .about {
    .container {
      max-width: 1080px;
    }
  }

  .mission_section {
    padding-top: 35px;
    padding-bottom: 37px;
    background-color: $fadeblue;
  }

    .our_mission {
      .intro-heading {
          max-width: 750px;
      }
    }



  .intro {
    .intro-text {
      .intro-heading {
        h2 {
          $min_width: 320px;
          $max_width: 1280px;
          $min_font: 15px;
          $max_font: 20px;
          $min_line: 21px;
          $max_line: 30px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);

          text-align: left;
          font-weight: 300;
          color: #2f2f2f;
          margin-top: 0;
          margin-bottom: .5em;
          padding-top: 10px;
          font-family: 'Nunito Sans', sans-serif;

          b {
            color: $primary;
            font-weight: 700;
          }
        }
        h5 {
          color: $primary;
          letter-spacing: .3rem;
          font-family: 'Roboto', sans-serif;
          font-weight: 600;
        }
      }
    }
    .our_mission {
      padding-top: 0;
    }
  }

  .mission {
    .head-contain {
      display: grid;
      justify-items: center;
    }

    .to-x {
      display: grid;
    }

    h2 {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 20px;
      font-weight: 300;
      margin-top: 0.2em;
      margin-bottom: .5em;
      color: #0d348f;
      line-height: 1.3;
      text-transform: uppercase;
    }

    h3 {
      font-family: 'Nunito Sans', sans-serif;
      margin-top: 0.4em;
      line-height: 1.25;
      color: #313131;
      max-width: 90%;
      font-size: 1.1em;
    }
  }

  .about {
    margin-top: 60px;
    .blue {
      color: $primary;
      font-weight: 400;
    }


    .uniweb-card {
      width: 310px;
      min-height: 220px;

      h3 {
        $min_width: 320px;
        $max_width: 1500px;
        $min_font: 22px;
        $max_font: 24px;
        $min_line: 30px;
        $max_line: 36px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-family: 'Metropolis', sans-serif;
        color: $primary;
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        $min_width: 320px;
        $max_width: 1500px;
        $min_font: 15px;
        $max_font: 17px;
        $min_line: 22px;
        $max_line: 28px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        font-family: 'Nunito Sans', sans-serif;
        align-self: end;
        margin-bottom: 0;
        font-weight: 300;
      }
    }


    .card-2 {
      box-shadow: 0 1px 3px rgba(11, 32, 87, 0.12), 0 1px 2px rgba(11, 32, 87, 0.24);
    }

    .card-columns {
      display: grid;
      justify-content: center;

      .card-nr-1 {
        align-self: end;
      }
      .card-nr-2 {
        align-self: start;
      }
      .card-nr-6 {
        align-self: end;
      }
      .card-nr-7 {
        align-self: start;
      }

    }

  }


  @media(min-width:450px) {
    .mission {
      margin-bottom: 100px;
    }
  }

  @media(min-width:768px) {
    .mission {
      margin-bottom: 0;
    }
  }


}
