.aboupage {
  #cases {
    .uw-card-on-white {
      -webkit-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
      -moz-box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
      box-shadow: 0 4px 15px rgba(200, 213, 243, 0.45);
    }
  }
}


#cases {
  padding-top: 0px;
  padding-bottom: 100px;
  background: $white;

  .container {
    max-width: 1280px !important;
  }

  .flex {
    display:flex;
    align-items:center;
    justify-content:center;
  }

  h3 {
    margin-top: 0;
    color: $primary;
  }

  img {
    width:100%;
    height:100%;
    vertical-align: middle;
    object-fit: cover;
  }

  .fx-card {
    margin-top: 50px;
    background-color: #fff;
    margin: 10px auto;
    max-width: 1000px;
    overflow-y: auto;
    height: 400px;
  }

  .fx-card__info {
    padding: 20px;
    height: 300px;
    $min_width: 320px;
    $max_width: 470px;
    $min_font: 15px;
    $max_font: 20px;
    $min_line: 26px;
    $max_line: 35px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);

    p, ul {
      margin-bottom: 0.6rem;
      font-weight: 300;
      font-family: 'Nunito Sans', sans-serif;
    }
  }

  .fx-card__title {
    text-transform: uppercase;
    font-size: 1.5em;
    margin-top: 0;
    $min_width: 320px;
    $max_width: 470px;
    $min_font: 19px;
    $max_font: 25px;
    $min_line: 30px;
    $max_line: 40px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
  }

  .fx-card__img {
    height: 300px;
  }

  @media (min-width:768px){
    .fx-card__info {
      padding: 30px 30px;
    }
    .fx-card {
      display: flex;
      overflow-y: hidden;
      height: 550px;
    }
    .fx-card__img {
      order: 0;
      flex: 0 0 400px;
      height: 100%;
    }
    .fx-card__info {
      order: 0;
      width: 100%;
      height: 100%;
      align-self: center;
      overflow-y: auto;
    }
  }


  .swiper-container {
    height: 450px;
  }

  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }


  @media (min-width:500px) {
    .swiper-container {
      height: 500px;
    }
  }

  @media (min-width:768px){
    .swiper-container {
      height: 650px;
    }
  }



  @media (min-width:992px){
    .swiper-button-prev {
      display: block;
    }
    .swiper-button-next {
      display: block;
    }
  }
}


.aboutpage {
  #cases {
    padding-top: 60px;
    padding-bottom: 70px;
    background: $fadeblue;
  }
}


