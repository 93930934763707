// Styling for the contact section
section#contact {
  background-color: $gray-900;
  //background-image: url('/Users/ulvisbobrovs/projects/webpack/assets/css/images/map-image.png');
  background-repeat: no-repeat;
  background-position: center;
  .section-heading {
    color: $white;
  }
  .form-group {
    margin-bottom: 25px;
    input,
    textarea {
      padding: 20px;
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 248px;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    //@include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    //@include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    //@include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    //@include heading-font;
  }
}


#contact_us {
  background-color: $fadeblue;
  padding-top: 0;
  padding-bottom: 70px;
  .contact-div {
    h3 {
      $min_width: 320px;
      $max_width: 1280px;
      $min_font: 19px;
      $max_font: 26px;
      $min_line: 32px;
      $max_line: 60px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
    }
    text-align: center;
    .contact-heading {
      color: $primary;
      margin-bottom: 1.20em;
    }
  }
}

@media (max-width:767px){
  .white-background {
    background-color: $fadeblue;
  }
}

@media (min-width:768px){
  .white-background {
    background-color: #fff !important;
  }
}