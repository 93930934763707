// Styling for the masthead
header.masthead {
  max-width: inherit;
  background-color: $white;
  background: url(../../staticImages/masthead.png);
  background-size: 125% auto;
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 50px;
  margin-top: 90px;
  text-align: left;
  min-height: 400px;

  .container {
    max-width: 1280px;
  }
  .intro-text {
    padding: 10px 15px 0;
    margin-top: 25%;
    .intro-heading {
      h1 {
        $min_width: 320px;
        $max_width: 1750px;
        $min_font: 20px;
        $max_font: 40px;
        $min_line: 29px;
        $max_line: 49px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        color: $text-dark;
        letter-spacing: -0.4;
        margin-bottom: 0.5em;
      }

      p {

        $min_width: 320px;
        $max_width: 1750px;
        $min_font: 13px;
        $max_font: 16px;
        $min_line: 20px;
        $max_line: 26px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);
        letter-spacing: -0.5;
        color: #5f6368;
        font-weight: 500;
        font-family: Metropolis;
      }
    }
  }
}

@media(min-width:450px) {
  header.masthead {
    padding: 10px 30px 0;
    .intro-text {
      margin-top: 45%;
    }
  }
}

@media(min-width:768px) {
  header.masthead {
    margin-top: 75px;
    .intro-text {
      margin-top: 44%;
      padding-right: 1rem;
    }
  }
}

@media(min-width:576px) {
  header.masthead {
    background-size: 100% auto;
  }
}

@media(min-width:1000px) {
  header.masthead {
    margin-top: 62px;
    padding-right: 70px;
    background-size: 130% auto;
    background-position: 70%;
    .intro-text {
      margin-top: 3%;
      padding: 0;
      padding-right: 5.1rem;
    }
  }
}

@media(min-width:1200px) {
  header.masthead {
    padding-right: 0;
  }
}

@media(min-width:1800px) {
  header.masthead {
    background-size: 110% auto;
  }
}

@media(min-width:1921px) {
  header.masthead {
    //background: url(../../staticImages/masthead-large.png);
    background-size: 100%;
    background-position: right;
    min-height: 500px;
    //background-repeat: no-repeat;
    //background-position: center;
    .intro-text {
      margin-top: 14%;
    }
  }
}