@import url('https://fonts.googleapis.com/css?family=Lato:400,900');

.Title {
  $min-height: 400px;
  $min_width: 320px;
  $max_width: 1500px;
  $min_font: 35px;
  $max_font: 50px;
  $min_line: 40px;
  $max_line: 60px;
  @include fluid-type($min_width, $max_width, $min_font, $max_font, $min_line, $max_line);

  color: $primary;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 65px;
  margin-top: 70px;
  padding-bottom: 0;
  font-family: 'Metropolis', sans-serif;
}

.Title-sub {
  font-size: 0.34em;
  letter-spacing: 0.16em;
  text-transform: none;
  display: block;
  margin: 15px auto;
  border-top: 0.2em solid;
  max-width: 70px;
}


@media(min-width:1200px) {
  .Title {
    margin-bottom: 75px;
    margin-top: 85px;
  }
}